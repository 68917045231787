import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PersonalInfo from "../about/PersonalInfo";
import Resume from "../about/Resume";
import Address from "../Address";
import Contact from "../Contact";
import Footer from "../footer/Footer";
import Portfolio from "../portfolio/Portfolio";
// import Service from "../service/Service";
import Testimonial from "../testimonial/Testimonial";

const tabMenuList = [
  { icon: "icon-archive", title: "About Me" },
  { icon: "icon-contacs", title: "Resume" },
  { icon: "icon-briefcase", title: "Portfolio" },
  // { icon: "icon-gear", title: "Service" },
  { icon: "icon-message", title: "Reviews" },
  { icon: "icon-letter", title: "Contact" },
];

const MainContentTabs = () => {
  return (
    <Tabs className="know_tm_hero">
      <div className="main_menu">
        <TabList>
          {tabMenuList.map((menu, i) => (
            <Tab key={i}>
              <div className={`svg ${menu.icon}`}></div>
              <span>{menu.title}</span>
            </Tab>
          ))}
        </TabList>
      </div>
      <div className="know_tm_mainpart">
        <TabPanel>
          <div className="know_tm_main_section">
            <PersonalInfo />
          </div>
        </TabPanel>

        <TabPanel>
          <div className="know_tm_main_section">
            <Resume />
          </div>
        </TabPanel>

        <TabPanel>
          <div className="know_tm_main_section">
            <Portfolio />
          </div>
        </TabPanel>

        {/* <TabPanel>
          <div className="know_tm_main_section">
            <Service />
          </div>
        </TabPanel> */}

        <TabPanel>
          <div className="know_tm_main_section">
            <div className="know_tm_testimonials">
              <div className="know_tm_main_title">
                <span>Reviews</span>
              </div>
              <div className="testimonials_list">
                <Testimonial />
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="know_tm_main_section">
            {/* <!-- CONTACT --> */}
            <div className="know_tm_contact">
              <div className="know_tm_main_title">
                <span>Contact Me</span>
              </div>
              <div className="wrapper">
                <div
                  className="left"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <Address />
                </div>
                {/* End .left */}
                <div
                  className="right"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="fields">
                    <Contact />
                  </div>
                </div>
                {/* End .right */}
              </div>
            </div>
            {/* End .know_tm_contact */}

            {/* <!-- /CONTACT --> */}
          </div>
        </TabPanel>
      </div>

      <div className="know_tm_copyright">
        <div className="container">
          <Footer />
        </div>
      </div>
    </Tabs>
  );
};

export default MainContentTabs;
