import React from "react";

const experienceContent = [
  {
    position: "Senior Blockchain Full Stack Developer | Lead Developer",
    compnayName: "Cubix | FL, US | Remote",
    year: "2020 - 2023",
  },
  {
    position: "Senior Full Stack Developer",
    compnayName: "Solwey Consulting | TX, US | Remote",
    year: "2017 - 2020",
  },
  {
    position: "Frontend Developer",
    compnayName: "Blink UX | CA, US | Remote",
    year: "2015 - 2017",
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="list_inner">
            <div className="short">
              <div className="job">
                <h3>{val.position}</h3>
                <span>{val.compnayName}</span>
              </div>
              <div className="year">
                <span>{val.year}</span>
              </div>
            </div>
            <div className="text">
              <p>{val.details}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
