const Header = () => {
  return (
    <div className="topbar_inner">
      {/* <div className="logo">
        <img src={logo} alt="logo" />
      </div> */}

      <div className="right">
        <div className="know_tm_button">
          <a href="img/cv/cv.pdf" download>
            Downlaod CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
