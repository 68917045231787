import "./App.css";
import { useEffect } from "react";
import AOS from "aos";
import AnimatedCursor from "react-animated-cursor";
import Home from "./views/Home";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <div className="kwno_tm_app_wrap">
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="255, 69, 27"
        outerAlpha={0.4}
        innerScale={0.7}
        outerScale={1.2}
      />
      <Home />
    </div>
  );
}

export default App;
