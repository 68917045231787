import Education from "./Education";
import Experience from "./Experience";
import SoftwareSkills from "./SoftwareSkills";

const Resume = () => {
  return (
    <>
      <div className="know_tm_resume" data-aos="fade-right">
        <div className="know_tm_main_title">
          <span>Resume</span>
        </div>

        <div className="resume_inner">
          <div className="left">
            <div className="info_list">
              <div className="know_tm_resume_title">
                <h3>Education</h3>
                <span className="shape"></span>
              </div>
              <Education />
            </div>
            <div className="info_list">
              <div className="know_tm_resume_title">
                <h3>Experience</h3>
                <span className="shape"></span>
              </div>
              <Experience />
            </div>
          </div>

          <div className="right">
            <div className="skills_list">
              <div className="know_tm_resume_title">
                <h3>Software Skills</h3>
                <span className="shape"></span>
              </div>
              <SoftwareSkills />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;
