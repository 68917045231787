import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonial() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          arrow: false,
          slidesToShow: 1,
          speed: 300,
        },
      },
    ],
  };

  const testimonialContent = [
    {
      desc: `It was great working with Mykola! I would highly recommend to anyone who is looking for a great developer to work on their project.`,
      // avatar: "1",
      name: "Salman Lakhani",
      designation: "CEO | Cubix",
      delayAnimation: "",
    },
    {
      desc: `It was a pleasure working with Mykola. Not only provided he me with great ideas. His communication and the result where fantastic!`,
      // avatar: "2",
      name: "Monika Jociunaite",
      designation: "Co-Founder | Solwey Consulting",
      delayAnimation: "100",
    },
    {
      desc: `Mykola, Amazing developer that gets it right the first time. The work is done great. He can do the hard stuff - not just making pretty websites.`,
      // avatar: "3",
      name: "Karen Clark Cole",
      designation: "CEO | Blink UX",
      delayAnimation: "200",
    },
  ];

  return (
    <Slider {...settings}>
      {testimonialContent.map((val, i) => (
        <div
          className="list_inner"
          key={i}
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay={val.delayAnimation}
        >
          <div className="in">
            <div className="text">
              <p>{val.desc}</p>
            </div>

            <div className="details">
              <div className="left">
                <div className="avatar">
                  <div
                    className="main"
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL +
                        `img/testimonials/${val.avatar}.jpg`
                      })`,
                    }}
                  ></div>
                </div>
                <div className="info">
                  <h3>{val.name}</h3>
                  <span>{val.designation}</span>
                </div>
              </div>

              <div className="right">
                <span className="icon-left-quote svg"></span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}
