import Header from "../components/header/Header";
import Hero from "../components/Hero/Hero";
import MainContentTabs from "../components/Hero/MainContentTabs";

const Home = () => {
  document.body.classList.add("dark");
  return (
    <>
      <div className="know_tm_topbar">
        <div className="container">
          <Header />
        </div>
      </div>
      <Hero />
      <div className="container">
        <MainContentTabs />
      </div>
    </>
  );
};

export default Home;
