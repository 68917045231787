const personalDetails = [
  { title: "Name", info: "Mykola Kharchenko" },
  { title: "Mail", info: "mk.honest99@gmail.com" },
  { title: "Phone", info: "+1 (785) 543 0115" },
  { title: "Address", info: "Budapest, Hungary" },
];

const aboutContentInfo = {
  meta: "About Me",
  title: "Full Stack developer",
  subTitle: "A Full Stack (Web, Blockchain, AI, ML) developer based in Hungary",
  description: `As an Innovative Developer with 8 years of experience specializing in AI, Python, MERN Stack and Blockchain technologies, I possess the expertise to create dynamic and intelligent web applications. My goal is to enhance the customer's business by providing solutions that promote the growth of their application. Additionally, I aim to present a vision for the future version of their business that aligns with their interests.`,
};

const PersonalInfo = () => {
  return (
    <div className="know_tm_about">
      <div className="left" data-aos="fade-right">
        <div className="left_inner">
          <div className="details">
            <ul>
              {personalDetails.map((value, i) => (
                <li key={i}>
                  <h3>{value.title}</h3>
                  <span>{value.info}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="right" data-aos="fade-right">
        <div className="know_tm_main_title">
          <span>{aboutContentInfo.meta}</span>
          <h3>{aboutContentInfo.title}</h3>
        </div>
        <div className="bigger_text">
          <p>{aboutContentInfo.subTitle}</p>
        </div>
        <div className="text">
          <p>{aboutContentInfo.description}</p>
        </div>
        <div className="know_tm_button">
          <a href="img/cv/cv.pdf" download>
            Download CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
