import React from "react";

const educationContent = [
  {
    degree: "Computer Science",
    institute:
      'Leiden University',
    year: "2011 - 2014",
    details: `Completed Bachelor's Degree in Computer Science`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="list_inner">
            <div className="short">
              <div className="job">
                <span style = {{ fontSize:"25px", fontWeight: "bold" }}>{val.institute}</span>
                <h3>{val.degree}</h3>
              </div>
              <div className="year">
                <span>{val.year}</span>
              </div>
            </div>
            <div className="text">
              <p>{val.details}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Education;
