import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const portfolioData = [
  {
    no: 1,
    url: "https://www.bicyclebluebook.com/",
    description: "Web - Bicycle Blue Book",
    technologies:
      "AI, ML, Algorithm, Angular, JavaScript, CSS, E-Commerce, Python, Django, React Native",
  },
  {
    no: 2,
    url: "https://wagmigame.io/",
    description: "Web & Mobile App - 3D NFT game",
    technologies:
      "React, JavaScript, C#, Ethereum, Binance, Tailwind CSS, Ethers.js, Node.js, Unity, Smart Contract",
  },
  {
    no: 3,
    url: "https://www.playtaunt.io/",
    description: "Web - Metaverse Fighting game",
    technologies:
      "React, JavaScript, Node.js, Polygon, Web3.js, Unity, CSS, React-Bootstrap, Solidity, Smart Contract",
  },
  {
    no: 4,
    url: "https://pokedx.app/",
    description: "Web - Pokedx",
    technologies:
      "React.js, JavaScript, jQuery, Core.js, Bootstrap, AWS S3, D3.js, Chart.js",
  },
  {
    no: 5,
    url: "https://winwinx.com/",
    description: "Web App - WinwinX",
    technologies:
      "React, Next.js, Typescript, Node.js, HubSpot, AWS, AuthO(Apple, Google)",
  },
  {
    no: 6,
    url: "https://www.easymedstat.com/",
    description: "Web - EasyMedStat",
    technologies:
      "Vue.js, JavaScript, Bootstrap, Styled-component, Python, Django, MariaDB, Chart.js",
  },
  {
    no: 7,
    url: "https://www.fcma.com/",
    description: "Web - Fcma",
    technologies:
      "Vue.js, JavaScrit, CSS3, PHP, Laravel, AWS, MySQL, Bootstrap, MySQL",
  },
  {
    no: 8,
    url: "https://matruecannabis.com/",
    description: "Web - MA Ture Cannabis",
    technologies:
      "React, Typescript, React-Bootstrap, MongoDB, Node.js, Three.js, AWS",
  },
  {
    no: 9,
    url: "https://www.koo-up.com/",
    description: "Web & Mobile - Kooup Dating and Meeting",
    technologies:
      "React, JavaScript, Tailwind CSS, AWS EC2, WebRTC, Node.js, AI(Matching Algorithms)",
  },
  {
    no: 10,
    url: "https://hyfi.pro/#/en",
    description: "Web - Hyfi staking",
    technologies:
      "React, Next.js, Typescript, Solidity, Ethereum, Web3.j, Node.js",
  },
  {
    no: 11,
    url: "https://coffins.tombstoned.app/",
    description: "Web - TombStoned Lootbox (Solana)",
    technologies:
      "React, Typescript, Tailwind CSS, Node.js, Express, Rust Smart Contract",
  },
  {
    no: 12,
    url: "https://yieldzprotocol.com/",
    description: "Web - Yieldz-Defi-Ecosystem",
    technologies: "React, Next.js, Typescript, Subgraph, Solidity, Web3.js",
  },
  {
    no: 13,
    url: "https://luckyrust.gg",
    description: "Web - Luckyrust Gambling",
    technologies:
      "React, JavaScript, Styled component, Node.js, Express, Websocket.io, RethinkDB",
  },
  {
    no: 14,
    url: "https://www.sprite.com/zerolimits",
    description: "Web - Hall Zero Limits",
    technologies: "Vue.js, JavaScript, GSAP, Three.js, AWS",
  },
  {
    no: 15,
    url: "https://ai-quest.lusion.co/",
    description: "Web - AI-Quest Story Game",
    technologies: "HTML, CSS, JavaScript, Bootstrap, Three.js, AWS",
  },
  {
    no: 16,
    url: "https://doodcats.net/",
    description: "Web - DedDoods NFT marketplace",
    technologies:
      "React, Tailiwind CSS, JavaScript, Web3.js, Node.js, Smart contract, Solidity",
  },
  {
    no: 17,
    url: "https://www.gustave.ai/",
    description: "AI SEO Article Generator",
    technologies:
      "React, Tailwind CSS, Tyepscript, OpenAI API, AI, ML, Python, PostgreSQL",
  },
  {
    no: 18,
    url: "",
    description: "Image-to-Text auto translator",
    technologies:
      "React, JavaScript, Python, Flask, FastAPI, Mongo Cluster, AWS, OCR, Tesseract, Pytorch, Spacy, OpenAI",
  },
];

const portfolioBlockchain = [
  {
    no: 2,
    url: "https://wagmigame.io/",
    description: "Web & Mobile App - 3D NFT game",
    technologies:
      "Angular, JavaScript, C#, Ethereum, Binance, Tailwind CSS, Ethers.js, Node.js, Unity, Smart Contract",
  },
  {
    no: 3,
    url: "https://www.playtaunt.io/",
    description: "Web - Metaverse Fighting game",
    technologies:
      "React, JavaScript, Node.js, Polygon, Web3.js, Unity, CSS, React-Bootstrap, Solidity, Smart Contract",
  },
  {
    no: 16,
    url: "https://doodcats.net/",
    description: "Web - DedDoods NFT marketplace",
    technologies:
      "React, Tailiwind CSS, JavaScript, Web3.js, Node.js, Smart contract, Solidity",
  },
  {
    no: 10,
    url: "https://hyfi.pro/#/en",
    description: "Web - Hyfi staking",
    technologies:
      "React, Next.js, Typescript, Solidity, Ethereum, Web3.j, Node.js",
  },
  {
    no: 11,
    url: "https://coffins.tombstoned.app/",
    description: "Web - TombStoned Lootbox (Solana)",
    technologies:
      "React, Typescript, Tailwind CSS, Node.js, Express, Rust Smart Contract",
  },
  {
    no: 12,
    url: "https://yieldzprotocol.com/",
    description: "Web - Yieldz-Defi-Ecosystem",
    technologies: "React, Next.js, Typescript, Subgraph, Solidity, Web3.js",
  },
  {
    no: 13,
    url: "https://luckyrust.gg",
    description: "Web - Luckyrust Gambling",
    technologies:
      "React, JavaScript, Styled-component, Node.js, Express, Websocket.io, RethinkDB",
  },
];

const portfolioWeb = [
  {
    no: 1,
    url: "https://www.bicyclebluebook.com/",
    description: "Web - Bicycle Blue Book",
    technologies:
      "AI, ML, Algorithm, Angular, JavaScript, CSS, E-Commerce, Python, Django, React Native",
  },
  {
    no: 4,
    url: "https://pokedx.app/",
    description: "Web - Pokedx",
    technologies:
      "React.js, JavaScript, jQuery, Core.js, Bootstrap, AWS S3, D3.js, Chart.js",
  },
  {
    no: 5,
    url: "https://winwinx.com/",
    description: "Web App - WinwinX",
    technologies:
      "React, Next.js, Typescript, Node.js, HubSpot, AWS, AuthO(Apple, Google)",
  },
  {
    no: 6,
    url: "https://www.easymedstat.com/",
    description: "Web - EasyMedStat",
    technologies:
      "Vue.js, JavaScript, Bootstrap, Styled-component, Python, Django, MariaDB, Chart.js",
  },
  {
    no: 14,
    url: "https://www.sprite.com/zerolimits",
    description: "Web - Hall Zero Limits",
    technologies: "Vue.js, JavaScript, GSAP, Three.js, AWS",
  },
  {
    no: 15,
    url: "https://ai-quest.lusion.co/",
    description: "Web - AI-Quest Story Game",
    technologies: "HTML, CSS, JavaScript, Bootstrap, Three.js, AWS",
  },
  {
    no: 7,
    url: "https://www.fcma.com/",
    description: "Web - Fcma",
    technologies:
      "Vue.js, JavaScrit, CSS3, PHP, Laravel, AWS, MySQL, Bootstrap, MySQL",
  },
  {
    no: 8,
    url: "https://matruecannabis.com/",
    description: "Web - MA Ture Cannabis",
    technologies:
      "React, Typescript, React-Bootstrap, MongoDB, Node.js, Three.js, AWS",
  },
  {
    no: 9,
    url: "https://www.koo-up.com/",
    description: "Web & Mobile - Kooup Dating and Meeting",
    technologies:
      "React, JavaScript, Tailwind CSS, AWS EC2, WebRTC, Node.js, AI(Matching Algorithms)",
  },
  {
    no: 17,
    url: "https://www.gustave.ai/",
    description: "AI SEO Article Generator",
    technologies:
      "React, Tailwind CSS, Tyepscript, OpenAI API, AI, ML, Python, PostgreSQL",
  },
];

const portfolioMobile = [
  {
    no: 9,
    url: "https://www.koo-up.com/",
    description: "Web & Mobile - Kooup Dating and Meeting",
    technologies:
      "React, JavaScript, Tailwind CSS, AWS EC2, WebRTC, Node.js, AI(Matching Algorithms)",
  },
  {
    no: 6,
    url: "https://www.easymedstat.com/",
    description: "Web - EasyMedStat",
    technologies:
      "Vue.js, JavaScript, Bootstrap, Styled-component, Python, Django, MariaDB, Chart.js",
  },
  {
    no: 1,
    url: "https://www.bicyclebluebook.com/",
    description: "Web - Bicycle Blue Book",
    technologies:
      "AI, ML, Algorithm, Angular, JavaScript, CSS, E-Commerce, Python, Django, React Native",
  },
];

const portfolioGame = [
  {
    no: 2,
    url: "https://wagmigame.io/",
    description: "Web & Mobile App - 3D NFT game",
    technologies:
      "Angular, JavaScript, C#, Ethereum, Binance, Tailwind CSS, Ethers.js, Node.js, Unity, Smart Contract",
  },
  {
    no: 3,
    url: "https://www.playtaunt.io/",
    description: "Web - Metaverse Fighting game",
    technologies:
      "React, JavaScript, Node.js, Polygon, Web3.js, Unity, CSS, React-Bootstrap,Rust, Smart Contract",
  },
  {
    no: 13,
    url: "https://luckyrust.gg",
    description: "Web - Luckyrust Gambling",
    technologies:
      "React, JavaScript, Styled component, Node.js, Express, Websocket.io, RethinkDB",
  },
  {
    no: 15,
    url: "https://ai-quest.lusion.co/",
    description: "Web - AI-Quest Story Game",
    technologies: "HTML, CSS, JavaScript, Bootstrap, Three.js, AWS",
  },
];

const portfolioAi = [
  {
    no: 1,
    url: "https://www.bicyclebluebook.com/",
    description: "Web - Bicycle Blue Book",
    technologies:
      "AI, ML, Algorithm, Angular, JavaScript, CSS, E-Commerce, Python, Django, React Native",
  },
  {
    no: 6,
    url: "https://www.easymedstat.com/",
    description: "Web - EasyMedStat",
    technologies:
      "Vue.js, JavaScript, Bootstrap, Styled-component, Python, Django, MariaDB, Chart.js",
  },
  {
    no: 9,
    url: "https://www.koo-up.com/",
    description: "Web & Mobile - Kooup Dating and Meeting",
    technologies:
      "React, JavaScript, Tailwind CSS, AWS EC2, WebRTC, Node.js, AI(Matching Algorithms), ML",
  },
  {
    no: 18,
    url: "",
    description: "Image-to-Text auto translator",
    technologies:
      "React, JavaScript, Python, Flask, FastAPI, Mongo Cluster, AWS, OCR, Tesseract, Pytorch, Spacy, OpenAI",
  },
  {
    no: 15,
    url: "https://ai-quest.lusion.co/",
    description: "Web - AI-Quest Story Game",
    technologies: "HTML, CSS, JavaScript, Bootstrap, Three.js, AWS, AI model",
  },
  {
    no: 17,
    url: "https://www.gustave.ai/",
    description: "AI SEO Article Generator",
    technologies:
      "React, Tailwind CSS, Tyepscript, OpenAI API, AI, ML, Python, PostgreSQL",
  },
];

const Portfolio = () => {
  return (
    <div className="know_tm_main_section">
      <div className="know_tm_portfolio">
        <Tabs>
          <TabList className="portfolio_filter">
            <Tab>All</Tab>
            <Tab>Blockchain</Tab>
            <Tab>Web</Tab>
            <Tab>Mobile</Tab>
            <Tab>Game</Tab>
            <Tab>AI, ML</Tab>
          </TabList>

          <div className="portfolio_list">
            <TabPanel>
              <ul className=" gallery_zoom">
                {portfolioData.map((data, i) => (
                  <a href={data.url} target="_blank" rel="noreferrer">
                    <li data-aos="fade-right" key={i} data-aos-delay="200">
                      <div className="list_inner">
                        <div className="image">
                          <img
                            src={`/img/portfolio/${i + 1}.jpg`}
                            alt="Details"
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="details">
                          <span>{data.technologies}</span>
                          <h3>{data.description}</h3>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
              </ul>
            </TabPanel>

            <TabPanel>
              <ul className=" gallery_zoom">
                {portfolioBlockchain.map((data) => (
                  <a href={data.url} target="_blank" rel="noreferrer">
                    <li data-aos="fade-right" data-aos-delay="200">
                      <div className="list_inner">
                        <div className="image">
                          <img
                            src={`/img/portfolio/${data.no}.jpg`}
                            alt="Details"
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="details">
                          <span>{data.technologies}</span>
                          <h3>{data.description}</h3>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
              </ul>
              {/* END PORTFOLIO LIST */}
            </TabPanel>

            <TabPanel>
              <ul className=" gallery_zoom">
                {portfolioWeb.map((data) => (
                  <a href={data.url} target="_blank" rel="noreferrer">
                    <li data-aos="fade-right" data-aos-delay="200">
                      <div className="list_inner">
                        <div className="image">
                          <img
                            src={`/img/portfolio/${data.no}.jpg`}
                            alt="Details"
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="details">
                          <span>{data.technologies}</span>
                          <h3>{data.description}</h3>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
              </ul>
              {/* END PORTFOLIO LIST */}
            </TabPanel>

            <TabPanel>
              <ul className=" gallery_zoom">
                {portfolioMobile.map((data) => (
                  <a href={data.url} target="_blank" rel="noreferrer">
                    <li data-aos="fade-right" data-aos-delay="200">
                      <div className="list_inner">
                        <div className="image">
                          <img
                            src={`/img/portfolio/${data.no}.jpg`}
                            alt="Details"
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="details">
                          <span>{data.technologies}</span>
                          <h3>{data.description}</h3>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
              </ul>
              {/* END PORTFOLIO LIST */}
            </TabPanel>

            <TabPanel>
              <ul className=" gallery_zoom">
                {portfolioGame.map((data) => (
                  <a href={data.url} target="_blank" rel="noreferrer">
                    <li data-aos="fade-right" data-aos-delay="200">
                      <div className="list_inner">
                        <div className="image">
                          <img
                            src={`/img/portfolio/${data.no}.jpg`}
                            alt="Details"
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="details">
                          <span>{data.technologies}</span>
                          <h3>{data.description}</h3>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
              </ul>
              {/* END PORTFOLIO LIST */}
            </TabPanel>

            <TabPanel>
              <ul className=" gallery_zoom">
                {portfolioAi.map((data) => (
                  <a href={data.url} target="_blank" rel="noreferrer">
                    <li data-aos="fade-right" data-aos-delay="200">
                      <div className="list_inner">
                        <div className="image">
                          <img
                            src={`/img/portfolio/${data.no}.jpg`}
                            alt="Details"
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="details">
                          <span>{data.technologies}</span>
                          <h3>{data.description}</h3>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
              </ul>
              {/* END PORTFOLIO LIST */}
            </TabPanel>
          </div>
          {/* END LIST WRAPPER */}
        </Tabs>
      </div>
      {/* <Modal
        isOpen={isOpen1}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/1-1.jpg)",
                    }}
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/2-2.jpg)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/3-3.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Metaverse Fighting game</h3>
                </div>
                <div className="textbox">
                  <p>React, JavaScript, Node.js, Polygon, Web3.js, Unity, CSS, React-Bootstrap,Rust, Smart Contract</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen4}
        onRequestClose={toggleModalFour}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFour}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/4-4.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Pokedx</h3>
                </div>
                <div className="textbox">
                  <p>React.js, JavaScript, jQuery, Core.js, Bootstrap, AWS S3, D3.js, Chart.js</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen5}
        onRequestClose={toggleModalFive}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFive}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/5-5.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web App - WinwinX</h3>
                </div>
                <div className="textbox">
                  <p>React, Next.js, Typescript, Node.js, HubSpot, AWS, AuthO(Apple, Google)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen6}
        onRequestClose={toggleModalSix}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSix}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/6-6.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - EasyMedStat</h3>
                </div>
                <div className="textbox">
                  <p>Vue.js, JavaScript, Bootstrap, Styled-component, Python, MariaDB, Chart.js</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen7}
        onRequestClose={toggleModalSeven}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSeven}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/7-7.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Fcma</h3>
                </div>
                <div className="textbox">
                  <p>Vue.js, JavaScrit, CSS3, Laravel, AWS, MySQL, Bootstrap, MySQL</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen8}
        onRequestClose={toggleModalEight}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalEight}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/8-8.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - MA Ture Cannabis</h3>
                </div>
                <div className="textbox">
                  <p>React, Typescript, React-Bootstrap, MongoDB, Node.js, Three.js, AWS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen9}
        onRequestClose={toggleModalNine}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalNine}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/9-9.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web & Mobile - Kooup Dating and Meeting</h3>
                </div>
                <div className="textbox">
                  <p>React, JavaScript, Tailwind CSS, AWS EC2, WebRTC, Node.js, AI(Matching Algorithms)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen10}
        onRequestClose={toggleModalTen}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalTen}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/10-10.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Hyfi staking</h3>
                </div>
                <div className="textbox">
                  <p>React, Next.js, Typescript, Solidity, Ethereum, Web3.j, Node.js</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen11}
        onRequestClose={toggleModalEleven}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalEleven}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/11-11.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Retro Pandas : NFT community</h3>
                </div>
                <div className="textbox">
                  <p>Bootstrap, JavaScript, CSS, PHP, Laravel, Solidity, Web3.js</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen12}
        onRequestClose={toggleModalTwelve}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalTwelve}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/12-12.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Yieldz-Defi-Ecosystem</h3>
                </div>
                <div className="textbox">
                  <p>React, Next.js, Typescript, Subgraph, Solidity, Web3.js</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen13}
        onRequestClose={toggleModalThirteen}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalThirteen}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/13-13.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Luckyrust Gambling</h3>
                </div>
                <div className="textbox">
                  <p>React, JavaScript, Styled component, Node.js, Express, Websocket.io, RethinkDB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen14}
        onRequestClose={toggleModalFourteen}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFourteen}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/14-14.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - Hall Zero Limits</h3>
                </div>
                <div className="textbox">
                  <p>Vue.js, JavaScript, GSAP, Three.js, AWS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen1}
        onRequestClose={toggleModalFifteen}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFifteen}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/15-15.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - AI-Quest Story Game</h3>
                </div>
                <div className="textbox">
                  <p>HTML, CSS, JavaScript, Bootstrap, Three.js, AWS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen16}
        onRequestClose={toggleModalSixteen}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSixteen}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/16-16.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>Web - DedDoods NFT marketplace</h3>
                </div>
                <div className="textbox">
                  <p>React, Tailiwind CSS, JavaScript, Web3.js, Node.js, SmartContract</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen17}
        onRequestClose={toggleModalSeventeen}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="know_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSeventeen}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="list_inner popup_details">
                <div className="top_image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: "url(/img/portfolio/17-17.jpg)",
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title ">
                  <h3>AI SEO Article Generator</h3>
                </div>
                <div className="textbox">
                  <p>React, Tailwind CSS, Tyepscript, OpenAI API, AI, ML, Python, PostgreSQL</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default Portfolio;
